"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const interval_rate_limiter_1 = require("../../../../classes/interval-rate-limiter");
const constants_1 = require("../../../../services/constants");
const graphql_1 = require("../../../../services/graphql");
const utilities_1 = require("../../../../services/utilities");
const auth_rules_1 = require("../../../../utilities/auth-rules");
const css_utils_1 = require("../../../../utilities/css-utils");
const dates_and_times_1 = require("../../../../utilities/dates-and-times/dates-and-times");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
const create_element_bound_interval_1 = require("../../../../utilities/timeouts/create-element-bound-interval");
const queries_1 = require("./queries");
const get_total_chats_based_on_status_1 = require("./utils/get-total-chats-based-on-status");
const get_total_chats_per_pod_1 = require("./utils/get-total-chats-per-pod");
const InitialState = {
    authenticatedUser: undefined,
    currentChats: [],
    chatQueueUpdateSubscription: 'NOT_SET',
    totalChatsPerPod: [],
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-chat-queue-status-box');
const chatQueueNotificationAudio = new Audio(constants_1.NEW_CHAT_NOTIFICATION_SOUND_URL);
class JOChatQueueStatusBox extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
        this.rateLimiter = new interval_rate_limiter_1.IntervalRateLimiter({
            callback: this.fetchAndSetChatInfo.bind(this),
            millisecondsPerInterval: 1000,
        });
    }
    connectedCallback() {
        (0, create_element_bound_interval_1.createElementBoundInterval)({
            callback: () => {
                this.determineToPlayNotificationSound();
            },
            element: this,
            intervalMs: dates_and_times_1.oneMinuteInMilliseconds,
            intervalName: '',
        });
        this.fetchAndSetChatInfo();
        if (this.store.chatQueueUpdateSubscription === 'NOT_SET') {
            this.subscribeToChatQueueUpdateEvent();
        }
    }
    disconnectedCallback() {
        if (this.store.chatQueueUpdateSubscription !== 'NOT_SET') {
            this.store.chatQueueUpdateSubscription.close();
        }
    }
    determineToPlayNotificationSound() {
        const numbChatsAssignedToUser = (0, get_total_chats_based_on_status_1.getTotalChatsAssignedToJill)(this.store.currentChats, this.store.authenticatedUser?.id);
        if (numbChatsAssignedToUser < 3 &&
            this.chatQueueHasUnassignedChats(this.store.currentChats) &&
            !(0, utilities_1.isOnCallScreen)() &&
            this.store.authenticatedUser &&
            (0, auth_rules_1.hasPermission)(this.store.authenticatedUser, 'has_chat_access')) {
            chatQueueNotificationAudio.play();
        }
    }
    async subscribeToChatQueueUpdateEvent() {
        this.store.chatQueueUpdateSubscription =
            (await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
                    subscription {
                        chatQueueUpdated
                    }
                `, () => {
                this.rateLimiter.trigger();
            })) ?? 'NOT_SET';
    }
    async fetchAndSetChatInfo() {
        const result = await (0, queries_1.getCurrentChats)();
        if (result.succeeded) {
            this.store.currentChats = result.value;
            this.determineToPlayNotificationSound();
        }
        else {
            throw new Error(`jo-chat/jo-chat-queue-status-box/jo-chat-queue-status-box.ts fetchAndSetChatInfo error: ${result}`);
        }
    }
    chatQueueHasUnassignedChats(currentChats) {
        return (0, get_total_chats_based_on_status_1.getTotalUnassignedChats)(currentChats) > 0;
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .${cssName('main-container')} {
                    width: 100%;
                    height: 100%;
                    font-size: 0.9rem;
                    color: black;
                    background-color: #e7b66b;
                    border-radius: 1rem;
                    padding: 0.3rem 0.5rem 0.3rem 1rem;
                }
                .${cssName('alert-icon')} {
                    position: relative;
                    top: 4px;
                    left: 13px;
                    color: var(--jo-danger);
                    text-shadow: 0px 1.5px 0px white;
                    animation: wiggle 2s;
                    animation-iteration-count: infinite;
                    cursor: pointer;
                }

                @keyframes wiggle {
                    0% {
                        transform: rotate(0deg);
                    }
                    25% {
                        transform: rotate(8deg);
                    }
                    50% {
                        transform: rotate(-8deg);
                    }
                    75% {
                        transform: rotate(8deg);
                    }
                    100% {
                        transform: rotate(0deg);
                    }
                }
            </style>

            <div
                title="${(0, get_total_chats_per_pod_1.getTotalChatsPerPodAsString)(state.currentChats)}"
                class="${cssName('main-container')}"
            >
                <div>Active Chats: ${(0, get_total_chats_based_on_status_1.getTotalActiveChats)(state.currentChats)}</div>

                <div>
                    Unassigned Chats: ${(0, get_total_chats_based_on_status_1.getTotalUnassignedChats)(state.currentChats)}
                    ${this.chatQueueHasUnassignedChats(state.currentChats)
            ? (0, lit_html_1.html) `
                              <i
                                  class="material-icons ${cssName('alert-icon')}"
                                  @click=${() => (0, utilities_1.navigate)('/chat-dashboard')}
                              >
                                  notification_important
                              </i>
                          `
            : ``}
                </div>
                <div>
                    Assigned To Me:
                    ${(0, get_total_chats_based_on_status_1.getTotalChatsAssignedToJill)(state.currentChats, state.authenticatedUser?.id)}
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-chat-queue-status-box', JOChatQueueStatusBox);
