"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFullClientInterface = void 0;
const common_1 = require("@augment-vir/common");
const web_client_interface_1 = require("./web-client-interface");
/**
 * Do not ever export this! ClientInterface should only be accessed through getWebClientInterface.
 *
 * This is marked deprecated so it's clear that it should not be used outside of this file.
 *
 * @deprecated
 */
const fullCurrentClientInterface = awaitAllClients((0, web_client_interface_1.getWebClientInterface)());
async function awaitAllClients(clientInterfaceSync) {
    return (await (0, common_1.mapObjectValues)(clientInterfaceSync, async (key, value) => {
        return await value;
    })); // as cast is necessary because mapObjectValues doesn't know the final return type
}
/** Returns all clients awaited at once rather than lazily loading each client. */
async function getFullClientInterface() {
    return await fullCurrentClientInterface;
}
exports.getFullClientInterface = getFullClientInterface;
