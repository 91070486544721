"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const common_1 = require("@augment-vir/common");
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const authenticate_1 = require("../../../services/auth/authenticate");
const store_1 = require("../../../services/store");
const utilities_1 = require("../../../services/utilities");
const is_in_admin_mode_1 = require("../../../utilities/admin-utils/is-in-admin-mode");
const auth_rules_1 = require("../../../utilities/auth-rules");
const css_utils_1 = require("../../../utilities/css-utils");
const dates_and_times_1 = require("../../../utilities/dates-and-times/dates-and-times");
const diff_time_1 = require("../../../utilities/dates-and-times/diff-time");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const end_admin_mode_if_necessary_1 = require("../../../utilities/end-admin-mode-if-necessary");
const user_status_event_mutations_1 = require("../../../utilities/logging-and-monitoring/user-status-event-mutations");
const logout_1 = require("../../../utilities/logout");
const personal_admin_1 = require("../../../utilities/personal-admin");
const get_jill_profile_picture_1 = require("../../../utilities/profile-picture/get-jill-profile-picture");
const create_element_bound_interval_1 = require("../../../utilities/timeouts/create-element-bound-interval");
const update_work_status_1 = require("../../../utilities/update-work-status");
const user_availability_1 = require("../../../utilities/user-availability");
const test_id_directive_1 = require("../../directives/test-id.directive");
require("../jo-availability");
const set_user_back_available_automatically_1 = require("../jo-call-screen/utilities/set-user-back-available-automatically");
require("../jo-chat/jo-chat-queue-status-box/jo-chat-queue-status-box");
require("../jo-incoming-queue-status-box/jo-incoming-queue-status-box");
require("../jo-loading");
const styles_1 = require("./styles");
const InitialState = {
    authenticatedUser: store_1.InitialGlobalState.authenticatedUser,
    loading: true,
    route: store_1.InitialGlobalState.route,
    isMobileDevice: false,
    showManageDropdown: false,
    showProfileDropdown: false,
    showWorkStatusDropdown: false,
    loggingOut: false,
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-top-bar');
const cssNames = (classNames) => classNames.split(' ').map(cssName).join(' ');
class JOTopBar extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    async connectedCallback() {
        // A lot of the jankiness seems to come because of the Material Icon fonts
        // This is designed to wait for the fonts to load before showing the UI
        // TODO beware, what if the fonts never load?
        await window.document.fonts.ready;
        (0, create_element_bound_interval_1.createElementBoundInterval)({
            callback: () => {
                this.store.dispatch({
                    type: 'RENDER',
                });
            },
            element: this,
            intervalMs: dates_and_times_1.oneSecondInMilliseconds,
            intervalName: 'force re-render',
        });
        this.store.loading = false;
    }
    // TODO this is now copied in jo-top-bar.ts and jo-customer-sidebar.ts
    async logoutClick() {
        try {
            if (this.store.authenticatedUser === 'NOT_SET' || this.store.loggingOut === true) {
                return;
            }
            this.store.loading = true;
            this.store.loggingOut = true;
            await (0, user_status_event_mutations_1.createUserStatusEvent)({
                userId: this.store.authenticatedUser.id,
                eventType: 'LOGGED_OUT',
            });
            await (0, user_availability_1.setUserUnavailable)(this.store.authenticatedUser.id);
            await (0, end_admin_mode_if_necessary_1.endAdminModeIfNecessary)(this.store.authenticatedUser);
            const logoutResult = await (0, logout_1.logout)();
            if (logoutResult === false) {
                throw new Error('Could not logout');
            }
            // we are doing this to ensure that all state stored in memory (mostly web sockets) is reset
            // there may be a better way to do this in the future...but this is simple and I do not think
            // provides a very poor UX. The alternative to ensure that web sockets are reset is to somehow reset
            // all websockets wherever they are stored on logout...this would be hard to do as websockets are stored
            // throughout multiple components, though we could also simply store all websockets in the global store,
            // but that might not be ideal either. So this is the tradeoff we are choosing for now
            window.location.assign('/login?sign_out=true');
            this.store.loggingOut = false;
        }
        catch (error) {
            alert(error);
        }
    }
    async changeWorkStatusClicked(userId, userStatusEventType) {
        this.store.showWorkStatusDropdown = false;
        (0, set_user_back_available_automatically_1.stopTimeoutToSetUserBackAvailable)();
        await handleChangeWorkStatus(userId, userStatusEventType);
    }
    render(state) {
        if (state.authenticatedUser === 'NOT_SET') {
            return (0, lit_html_1.html) ``;
        }
        const authenticatedUser = state.authenticatedUser;
        const isOnCallScreen = (0, utilities_1.getIsOnCallScreen)(state.route);
        const defaultProfilePictureHtml = (0, lit_html_1.html) `
            <span class="material-icons ${cssNames('nav-icon')}" style="font-size:2rem">
                account_circle
            </span>
        `;
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            ${styles_1.stylesForJoTopBar}

            <jo-loading
                .props=${{
            loading: state.loading,
        }}
            ></jo-loading>

            <div
                class="${cssNames('main-container')} ${state.isMobileDevice
            ? cssNames('overflow-x-scroll')
            : cssNames('overflow-x-hidden')}"
            >
                <section class="${cssNames('left-container')}">
                    <div class="${cssNames('item')}">
                        <img
                            src="../images/jo-logo.png"
                            class="${cssNames('logo')} ${isOnCallScreen === true
            ? 'jo-top-bar--disabled'
            : ''}"
                            @click=${() => isOnCallScreen === false && (0, utilities_1.navigate)('/')}
                        />
                    </div>
                </section>

                <section class="${cssNames('availability-container')}">
                    ${(0, auth_rules_1.isJillAtOrAboveAuthRole)(state.authenticatedUser, 'JILL_OUTBOUND')
            ? this.getIncomingQueueStatusBoxHtml(isOnCallScreen)
            : ''}

                    <div
                        class="${cssNames('item')}"
                        ?hidden=${shouldShowAvailabilityButton(state, isOnCallScreen) === false}
                    >
                        <jo-availability
                            .authenticatedUser=${state.authenticatedUser}
                        ></jo-availability>
                    </div>

                    <div ?hidden=${state.authenticatedUser.work_status !== 'ON_BREAK'}>
                        <span>
                            On Break 🕔 for ${makeTimeSinceLastUserEventString(authenticatedUser)}
                        </span>
                    </div>

                    <div ?hidden=${state.authenticatedUser.work_status !== 'ON_LUNCH'}>
                        <span>
                            On Lunch 🍟 for ${makeTimeSinceLastUserEventString(authenticatedUser)}
                        </span>
                    </div>

                    <div ?hidden=${state.authenticatedUser.work_status !== 'IN_TEAM_HUDDLE'}>
                        <span>
                            In Huddle/1:1 🕔 for
                            ${makeTimeSinceLastUserEventString(authenticatedUser)}
                        </span>
                    </div>
                    <div ?hidden=${state.authenticatedUser.work_status !== 'IN_OFF_CALL_TRAINING'}>
                        <span>
                            In Training 🏫 for
                            ${makeTimeSinceLastUserEventString(authenticatedUser)}
                        </span>
                    </div>

                    <div ?hidden=${(0, is_in_admin_mode_1.isInAdminMode)(state.authenticatedUser) === false}>
                        <span>
                            In Admin Mode for
                            ${getNameOfCompanyForMostRecentStatusEvent(authenticatedUser)} for
                            ${makeTimeSinceLastUserEventString(authenticatedUser)}
                        </span>
                    </div>

                    ${(0, auth_rules_1.hasPermission)(authenticatedUser, 'has_chat_access') ||
            (0, auth_rules_1.isJillAtOrAboveAuthRole)(authenticatedUser, 'JILL_MANAGER')
            ? makeChatQueueStatusBoxHtml(isOnCallScreen, authenticatedUser)
            : ''}
                </section>

                <section class="${cssNames('nav-items-container')}">
                    <div
                        class="${cssNames('item nav-item')} ${isOnCallScreen === true
            ? 'jo-top-bar--disabled'
            : ''}"
                        @mouseover=${() => {
            if (isOnCallScreen === false) {
                this.store.showWorkStatusDropdown = true;
            }
        }}
                        @mouseout=${() => (this.store.showWorkStatusDropdown = false)}
                    >
                        Status
                        <span
                            ${(0, test_id_directive_1.testId)('jo-top-bar-status-dropdown-content')}
                            class="material-icons ${cssNames('nav-icon')}"
                        >
                            expand_more
                        </span>

                        <div
                            ?hidden=${state.showWorkStatusDropdown === false}
                            class="${cssNames('manage-dropdown-content')}"
                        >
                            ${this.makeWorkStatusOptionsHTML(authenticatedUser)}
                        </div>
                    </div>

                    <div
                        class="${cssNames('item nav-item')} ${isOnCallScreen
            ? 'jo-top-bar--disabled'
            : ''}"
                        @mouseover=${() => {
            if (isOnCallScreen === false) {
                this.store.showManageDropdown = true;
            }
        }}
                        @mouseout=${() => (this.store.showManageDropdown = false)}
                    >
                        <a ${(0, test_id_directive_1.testId)('jo-top-bar-manage')}>
                            Manage
                            <span class="material-icons ${cssNames('nav-icon')}">expand_more</span>
                        </a>
                        <div
                            ${(0, test_id_directive_1.testId)('jo-top-bar-manage-drop-down-content')}
                            ?hidden=${state.showManageDropdown === false}
                            class="${cssNames('manage-dropdown-content')}"
                            @click=${() => (this.store.showManageDropdown = false)}
                        >
                            ${(0, personal_admin_1.isPersonalAdmin)(state.authenticatedUser) &&
            (0, is_in_admin_mode_1.isInAdminMode)(state.authenticatedUser)
            ? (0, lit_html_1.html) `
                                      <a @click=${() => (0, utilities_1.navigate)('/personal-admin-dashboard')}>
                                          Personal Admin Dashboard
                                      </a>
                                  `
            : ''}

                            <a ${(0, test_id_directive_1.testId)('jo-top-bar-users')} @click=${() => (0, utilities_1.navigate)(`/users`)}>
                                Users
                            </a>
                            <a
                                ${(0, test_id_directive_1.testId)('jo-top-bar-companies')}
                                @click=${() => (0, utilities_1.navigate)(`/companies`)}
                            >
                                Companies
                            </a>

                            ${(0, utilities_1.isInAuthRole)(['ALL_JILLS'], state.authenticatedUser) &&
            (0, auth_rules_1.hasPermission)(state.authenticatedUser, 'has_chat_access')
            ? (0, lit_html_1.html) `
                                      <a
                                          ${(0, test_id_directive_1.testId)('jo-top-bar-chat-dashboard')}
                                          @click=${() => (0, utilities_1.navigate)(`/chat-dashboard`)}
                                      >
                                          Chat Dashboard
                                      </a>
                                  `
            : ''}
                            ${(0, auth_rules_1.isJillAtOrAboveAuthRole)(state.authenticatedUser, 'JILL_OUTBOUND')
            ? (0, lit_html_1.html) `
                                      <a
                                          ${(0, test_id_directive_1.testId)('jo-top-bar-teams-page')}
                                          @click=${() => (0, utilities_1.navigate)(`/teams`)}
                                      >
                                          Teams
                                      </a>
                                      <a
                                          ${(0, test_id_directive_1.testId)('jo-top-bar-outbound-dashboard')}
                                          @click=${() => (0, utilities_1.navigate)(`/outbound-dashboard`)}
                                      >
                                          Outbound Dashboard
                                      </a>
                                  `
            : ''}
                            <a
                                ?hidden=${!(0, utilities_1.isInAuthRole)([
            'JILL_OUTBOUND',
            'JILL_MANAGER',
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser)}
                                ${(0, test_id_directive_1.testId)('jo-top-bar-incoming-queue-report')}
                                @click=${() => (0, utilities_1.navigate)(`/incoming-queue-report`)}
                            >
                                Incoming Queue Report
                            </a>
                            ${(0, utilities_1.authorizedRender)([
            'JILL_MANAGER',
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser, (0, lit_html_1.html) `
                                    <a
                                        ${(0, test_id_directive_1.testId)('jo-top-bar-pods')}
                                        @click=${() => (0, utilities_1.navigate)(`/pods`)}
                                    >
                                        Pods
                                    </a>
                                    <a
                                        ${(0, test_id_directive_1.testId)('jo-top-bar-account-help')}
                                        @click=${() => (0, utilities_1.navigate)(`/account-help`)}
                                    >
                                        Account Help
                                    </a>
                                    <a
                                        ${(0, test_id_directive_1.testId)('jo-top-bar-jills-pods')}
                                        @click=${() => (0, utilities_1.navigate)(`/jills-pods`)}
                                    >
                                        Jills Pods
                                    </a>
                                    <a
                                        ${(0, test_id_directive_1.testId)('jill-high-level-report')}
                                        @click=${() => (0, utilities_1.navigate)(`/jill-high-level-report`)}
                                    >
                                        Jill High Level Report
                                    </a>
                                    <a @click=${() => (0, utilities_1.navigate)(`/chat-settings`)}>Chat Settings</a>
                                `)}
                            ${(0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser)
            ? (0, lit_html_1.html) `
                                      <a @click=${() => (0, utilities_1.navigate)(`/high-level-report`)}>
                                          High-level Report
                                      </a>
                                      <a @click=${() => (0, utilities_1.navigate)(`/billing-report`)}>
                                          Billing Report
                                      </a>
                                      <a
                                          ${(0, test_id_directive_1.testId)('jo-top-bar-chat-stats')}
                                          @click=${() => (0, utilities_1.navigate)(`/chat-stats-overview`)}
                                      >
                                          Chat Stats
                                      </a>
                                      <a @click=${() => (0, utilities_1.navigate)(`/control-panel`)}>
                                          Control Panel
                                      </a>
                                      <a
                                          ${(0, test_id_directive_1.testId)('jo-top-bar-register-company')}
                                          @click=${() => (0, utilities_1.navigate)(`/register-company`)}
                                      >
                                          Register Company
                                      </a>
                                      <a
                                          ${(0, test_id_directive_1.testId)('jo-top-bar-quick-text')}
                                          @click=${() => (0, utilities_1.navigate)(`/quick-text-dashboard`)}
                                      >
                                          Quick Text
                                      </a>
                                      <a @click=${() => (0, utilities_1.navigate)(`/graphql`)}>GraphQL</a>
                                      <a @click=${() => (0, utilities_1.navigate)(`/yearly-report`)}>
                                          Yearly Report
                                      </a>
                                      <a
                                          ${(0, test_id_directive_1.testId)('jo-top-bar-billing-packages')}
                                          @click=${() => (0, utilities_1.navigate)(`/billing-packages`)}
                                      >
                                          Billing Packages
                                      </a>
                                      <a
                                          href="/oss-attribution/attribution.txt"
                                          style="text-decoration: none"
                                      >
                                          Open Source
                                      </a>
                                  `
            : ''}
                            ${(0, utilities_1.authorizedRender)(['JILL_EXECUTIVE'], state.authenticatedUser, (0, lit_html_1.html) `
                                    <a @click=${() => (0, utilities_1.navigate)(`/queue-settings`)}>
                                        Queue Settings
                                    </a>
                                `)}
                        </div>
                    </div>

                    <div
                        class="${cssNames('item nav-item')} ${isOnCallScreen === true
            ? 'jo-top-bar--disabled'
            : ''}"
                        @click=${() => isOnCallScreen === false && (0, utilities_1.navigate)('/')}
                    >
                        <span class="material-icons ${cssNames('nav-icon')}" style="font-size:2rem">
                            home
                        </span>
                    </div>

                    <div
                        ${(0, test_id_directive_1.testId)('jo-top-bar-profile-picture')}
                        class="${cssNames('profile-icon-container')}
                        ${isOnCallScreen === true ? 'jo-top-bar--disabled' : ''}"
                        title="Profile"
                        @mouseover=${() => {
            if (isOnCallScreen === false) {
                this.store.showProfileDropdown = true;
            }
        }}
                        @mouseout=${() => (this.store.showProfileDropdown = false)}
                    >
                        ${state.authenticatedUser.profile_picture_url
            ? this.getHTMLForJillProfilePicture(state.authenticatedUser.profile_picture_url)
            : defaultProfilePictureHtml}
                    </div>

                    <div
                        ${(0, test_id_directive_1.testId)('jo-top-bar-profile-drop-down-container')}
                        ?hidden=${this.store.showProfileDropdown === false}
                        class="${cssNames('profile-dropdown-content')}"
                        @mouseover=${() => {
            this.store.showProfileDropdown = true;
        }}
                        @mouseout=${() => (this.store.showProfileDropdown = false)}
                        @click=${() => (this.store.showProfileDropdown = false)}
                    >
                        <div>
                            <img
                                src="${(0, get_jill_profile_picture_1.getJillProfilePictureUrl)(state.authenticatedUser)}"
                                style="max-height: 7rem"
                            />
                        </div>

                        <div>${state.authenticatedUser.first_name}</div>

                        <div ${(0, test_id_directive_1.testId)('jo-top-bar-user-email')}>
                            ${state.authenticatedUser.email}
                        </div>

                        <a
                            ${(0, test_id_directive_1.testId)('jo-top-bar-change-password')}
                            href="${window.location.origin}/change-user-password"
                        >
                            Change Password
                        </a>

                        <a
                            href="${window.location.origin}/profile-settings?id=${state
            .authenticatedUser.id}"
                        >
                            Settings
                        </a>

                        <jo-button
                            ${(0, test_id_directive_1.testId)('jo-top-bar-logout-button')}
                            ?hidden=${this.shouldHideLogoutButton(state.authenticatedUser)}
                            .disabled=${isOnCallScreen === true}
                            .type=${'ACTION_BUTTON_1'}
                            .text=${'Logout'}
                            @joButtonClick=${() => isOnCallScreen === false && this.logoutClick()}
                        ></jo-button>
                    </div>
                </section>
            </div>
        `;
    }
    getHTMLForJillProfilePicture(profilePictureUrl) {
        return (0, lit_html_1.html) `
            <img src="${profilePictureUrl}" style="max-height:50px" />
        `;
    }
    getIncomingQueueStatusBoxHtml(isOnCallScreen) {
        if (this.store.authenticatedUser === 'NOT_SET' ||
            this.store.authenticatedUser.permissions?.can_view_incoming_queue_box === false) {
            return (0, lit_html_1.html) ``;
        }
        return (0, lit_html_1.html) `
            <div ?hidden=${isOnCallScreen || !(0, utilities_1.userIsJill)(this.store.authenticatedUser)}>
                <jo-incoming-queue-status-box
                    .authenticatedUser=${this.store.authenticatedUser}
                ></jo-incoming-queue-status-box>
            </div>
        `;
    }
    shouldHideLogoutButton(authenticatedUser) {
        return ((0, is_in_admin_mode_1.isInAdminMode)(authenticatedUser) ||
            authenticatedUser.work_status === 'ON_BREAK' ||
            authenticatedUser.work_status === 'ON_LUNCH' ||
            authenticatedUser.work_status === 'IN_TEAM_HUDDLE' ||
            authenticatedUser.work_status === 'IN_OFF_CALL_TRAINING');
    }
    makeWorkStatusOptionsHTML(authenticatedUser) {
        if (authenticatedUser.in_personal_admin_mode ||
            authenticatedUser.work_status === 'IN_ADMIN_MODE') {
            return (0, lit_html_1.html) `
                <a>End Admin Mode to see more options</a>
            `;
        }
        if (authenticatedUser.work_status === 'CLOCKED_OUT') {
            return (0, lit_html_1.html) `
                <a
                    ${(0, test_id_directive_1.testId)('jo-top-bar-clock-in-status')}
                    @click=${() => this.changeWorkStatusClicked(authenticatedUser.id, 'CLOCKED_IN')}
                >
                    Clock In
                    <span class="material-icons-outlined">punch_clock</span>
                </a>
            `;
        }
        if (authenticatedUser.work_status === 'ON_BREAK') {
            return (0, lit_html_1.html) `
                <a
                    ${(0, test_id_directive_1.testId)('jo-top-bar-end-break-status')}
                    @click=${() => this.changeWorkStatusClicked(authenticatedUser.id, 'RETURNED_FROM_BREAK')}
                >
                    End Break
                    <span class="material-icons-outlined">timer</span>
                </a>
            `;
        }
        if (authenticatedUser.work_status === 'ON_LUNCH') {
            return (0, lit_html_1.html) `
                <a
                    ${(0, test_id_directive_1.testId)('jo-top-bar-end-lunch-status')}
                    @click=${() => this.changeWorkStatusClicked(authenticatedUser.id, 'RETURNED_FROM_LUNCH')}
                >
                    End Lunch
                    <span class="material-icons-outlined">dinner_dining</span>
                </a>
            `;
        }
        if (authenticatedUser.work_status === 'IN_TEAM_HUDDLE') {
            return (0, lit_html_1.html) `
                <a
                    ${(0, test_id_directive_1.testId)('jo-top-bar-end-team-huddle')}
                    @click=${() => this.changeWorkStatusClicked(authenticatedUser.id, 'ENDED_TEAM_HUDDLE')}
                >
                    End Huddle/1:1
                    <span class="material-icons-outlined">timer</span>
                </a>
            `;
        }
        if (authenticatedUser.work_status === 'IN_OFF_CALL_TRAINING') {
            return (0, lit_html_1.html) `
                <a
                    ${(0, test_id_directive_1.testId)('jo-top-bar-end-training')}
                    @click=${() => this.changeWorkStatusClicked(authenticatedUser.id, 'ENDED_OFF_CALL_TRAINING')}
                >
                    End Training
                    <span class="material-icons-outlined">timer</span>
                </a>
            `;
        }
        return (0, lit_html_1.html) `
            <a
                ${(0, test_id_directive_1.testId)('jo-top-bar-go-on-break-status')}
                @click=${() => this.changeWorkStatusClicked(authenticatedUser.id, 'WENT_ON_BREAK')}
            >
                Go on Break
                <span class="material-icons-outlined">timer</span>
            </a>
            <a
                ${(0, test_id_directive_1.testId)('jo-top-bar-go-to-lunch-status')}
                @click=${() => this.changeWorkStatusClicked(authenticatedUser.id, 'WENT_ON_LUNCH')}
            >
                Go on Lunch
                <span class="material-icons-outlined">restaurant</span>
            </a>
            <a
                ${(0, test_id_directive_1.testId)('jo-top-bar-team-huddle-status')}
                @click=${() => this.changeWorkStatusClicked(authenticatedUser.id, 'BEGAN_TEAM_HUDDLE')}
            >
                Huddle/1:1
                <span class="material-icons-outlined">groups</span>
            </a>
            <a
                ${(0, test_id_directive_1.testId)('jo-top-bar-training-status')}
                @click=${() => this.changeWorkStatusClicked(authenticatedUser.id, 'BEGAN_OFF_CALL_TRAINING')}
            >
                Training
                <span class="material-icons-outlined">school</span>
            </a>
            <a
                ${(0, test_id_directive_1.testId)('jo-top-bar-clock-out')}
                @click=${() => this.changeWorkStatusClicked(authenticatedUser.id, 'CLOCKED_OUT')}
            >
                Clock Out
                <span class="material-icons-outlined">punch_clock</span>
            </a>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-top-bar', JOTopBar);
function makeChatQueueStatusBoxHtml(isOnCallScreen, authenticatedUser) {
    if (isOnCallScreen || !(0, auth_rules_1.hasPermission)(authenticatedUser, 'has_chat_access')) {
        return (0, lit_html_1.html) ``;
    }
    return (0, lit_html_1.html) `
        <div>
            <jo-chat-queue-status-box
                .authenticatedUser=${authenticatedUser}
            ></jo-chat-queue-status-box>
        </div>
    `;
}
function makeTimeSinceLastUserEventString(authenticatedUser) {
    if (!authenticatedUser.user_status_events ||
        authenticatedUser.user_status_events.length === 0) {
        return 'Unknown';
    }
    const now = new Date();
    const timeSinceLastUserEvent = getTimeSinceLastUserEvent(authenticatedUser);
    return (0, utilities_1.millisecondsToHoursMinutesSeconds)(timeSinceLastUserEvent);
}
function getTimeSinceLastUserEvent(authenticatedUser) {
    const now = new Date();
    (0, common_1.assertLengthAtLeast)(authenticatedUser.user_status_events, 1);
    return (0, diff_time_1.diffTime)(now, authenticatedUser.user_status_events[0].timestamp);
}
function getNameOfCompanyForMostRecentStatusEvent(user) {
    try {
        if (user === null ||
            user === undefined ||
            user === 'NOT_SET' ||
            !(0, common_1.isLengthAtLeast)(user.user_status_events, 1)) {
            return '';
        }
        return user.user_status_events[0].company?.name ?? 'Unknown Company';
    }
    catch (error) {
        throw new Error(`getNameOfCompanyForMostRecentStatusEvent error: ${error}`);
    }
}
function shouldShowAvailabilityButton(state, isOnCallScreen) {
    if (store_1.GlobalStore.incomingTwilioConnection !== undefined ||
        state.authenticatedUser === 'NOT_SET' ||
        state.authenticatedUser.in_personal_admin_mode === true ||
        isOnCallScreen === true ||
        state.authenticatedUser.work_status === 'ON_BREAK' ||
        state.authenticatedUser.work_status === 'ON_LUNCH' ||
        state.authenticatedUser.work_status === 'IN_TEAM_HUDDLE' ||
        state.authenticatedUser.work_status === 'IN_OFF_CALL_TRAINING' ||
        state.authenticatedUser.work_status === 'CLOCKED_OUT')
        return false;
    return true;
}
async function handleChangeWorkStatus(userId, statusEventType) {
    try {
        const timestamp = new Date();
        await (0, user_availability_1.setUserUnavailable)(userId);
        await (0, user_status_event_mutations_1.createUserStatusEvent)({
            userId: userId,
            eventType: statusEventType,
            timestamp: timestamp,
        });
        await handleUpdateWorkStatus(userId, statusEventType);
        await (0, authenticate_1.authenticate)();
    }
    catch (error) {
        (0, utilities_1.handleError)(`handleChangeStatus error: ${error.message}`);
    }
}
async function handleUpdateWorkStatus(userId, statusEventType) {
    const workStatus = getMatchingWorkStatusForUserStatusEvent(statusEventType);
    await (0, update_work_status_1.updateWorkStatus)(userId, workStatus);
}
function getMatchingWorkStatusForUserStatusEvent(userStatusEventType) {
    if (userStatusEventType === 'CLOCKED_IN') {
        return 'CLOCKED_IN';
    }
    if (userStatusEventType === 'CLOCKED_OUT') {
        return 'CLOCKED_OUT';
    }
    if (userStatusEventType === 'WENT_ON_BREAK') {
        return 'ON_BREAK';
    }
    if (userStatusEventType === 'RETURNED_FROM_BREAK') {
        return 'CLOCKED_IN';
    }
    if (userStatusEventType === 'WENT_ON_LUNCH') {
        return 'ON_LUNCH';
    }
    if (userStatusEventType === 'RETURNED_FROM_LUNCH') {
        return 'CLOCKED_IN';
    }
    if (userStatusEventType === 'BEGAN_TEAM_HUDDLE') {
        return 'IN_TEAM_HUDDLE';
    }
    if (userStatusEventType === 'ENDED_TEAM_HUDDLE') {
        return 'CLOCKED_IN';
    }
    if (userStatusEventType === 'BEGAN_OFF_CALL_TRAINING') {
        return 'IN_OFF_CALL_TRAINING';
    }
    if (userStatusEventType === 'ENDED_OFF_CALL_TRAINING') {
        return 'CLOCKED_IN';
    }
    throw new Error(`getMatchingWorkStatusForUserStatusEvent error: unknown userStatusEventType ${userStatusEventType}`);
}
