"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.saveGoogleAuthTokenIfNecessary = void 0;
const save_google_auth_token_1 = require("../utilities/google/save-google-auth-token");
const utilities_1 = require("./utilities");
async function saveGoogleAuthTokenIfNecessary() {
    try {
        if (doesURLContainsGoogleAuthCode(window.location.href) === false ||
            doesURLContainsCompanyState(window.location.href) === false) {
            return;
        }
        const [code, state,] = getItemsFromUrlSearchParams([
            'code',
            'state',
        ]);
        if (!code) {
            throw new Error('Google code not found in url query params');
        }
        if (!state) {
            throw new Error('state not found in url query params');
        }
        const companyId = getCompanyID(state);
        await (0, save_google_auth_token_1.saveGoogleAuthToken)(companyId, code);
        (0, utilities_1.navigate)('/customer/company-calendars');
    }
    catch (error) {
        throw new Error(`saveGoogleAuthTokenIfNecessary error: ${error}`);
    }
}
exports.saveGoogleAuthTokenIfNecessary = saveGoogleAuthTokenIfNecessary;
function getItemsFromUrlSearchParams(attributes) {
    return attributes.map(attr => new URLSearchParams(window.location.search).get(attr));
}
function doesURLContainsGoogleAuthCode(url) {
    return url.includes('code=');
}
function doesURLContainsCompanyState(url) {
    return url.includes('state=');
}
function getCompanyID(state) {
    try {
        return JSON.parse(state).companyId;
    }
    catch (error) {
        throw new Error(`getCompanyID error: ${error}`);
    }
}
