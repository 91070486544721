"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCurrentChats = void 0;
const graphql_1 = require("../../../../services/graphql");
const error_handling_1 = require("../../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function getCurrentChats() {
    try {
        const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                query {
                    findChats: findChats(
                        filter: {status: {in: ["OPEN", "ONGOING", "RELEASED_BY_JILL"]}}
                    ) {
                        items {
                            id
                            status
                            pod {
                                id
                                name
                            }
                            currently_chatting_user {
                                id
                            }
                        }
                    }
                }
            `);
        if (result.succeeded === false)
            return result;
        const chatList = result.value.data.findChats.items;
        return {
            succeeded: true,
            value: chatList,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('jo-chat-queue-status-box -> queries.ts -> getCurrentChats()', error);
    }
}
exports.getCurrentChats = getCurrentChats;
