"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTotalChatsAssignedToJill = exports.getTotalUnassignedChats = exports.getTotalActiveChats = void 0;
function getTotalActiveChats(currentChats) {
    return currentChats.filter(chat => {
        if (chat.status === 'ONGOING') {
            return chat;
        }
    }).length;
}
exports.getTotalActiveChats = getTotalActiveChats;
function getTotalUnassignedChats(currentChats) {
    return currentChats.filter(chat => {
        if (chat.status === 'OPEN' || chat.status === 'RELEASED_BY_JILL') {
            return chat;
        }
    }).length;
}
exports.getTotalUnassignedChats = getTotalUnassignedChats;
function getTotalChatsAssignedToJill(currentChats, userId) {
    return currentChats.filter(chat => chat.status === 'ONGOING' && chat.currently_chatting_user?.id === userId).length;
}
exports.getTotalChatsAssignedToJill = getTotalChatsAssignedToJill;
