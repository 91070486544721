"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const store_1 = require("../../services/store");
const utilities_1 = require("../../services/utilities");
const css_utils_1 = require("../../utilities/css-utils");
const user_status_event_mutations_1 = require("../../utilities/logging-and-monitoring/user-status-event-mutations");
const set_user_back_available_automatically_1 = require("./jo-call-screen/utilities/set-user-back-available-automatically");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const user_availability_1 = require("../../utilities/user-availability");
const InitialState = {
    authenticatedUser: store_1.InitialGlobalState.authenticatedUser,
    callAnswered: store_1.InitialGlobalState.currentCallInformation.callAnswered,
    callIncoming: store_1.InitialGlobalState.currentCallInformation.callIncoming,
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-availability');
class JOAvailability extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    async availabilityClicked(e) {
        if (store_1.GlobalStore.incomingTwilioConnection !== undefined) {
            return;
        }
        const state = this.store.getState();
        if (e.detail >= 2 || state.callIncoming === true || state.callAnswered === true) {
            return;
        }
        (0, utilities_1.assertIsSet)(state.authenticatedUser, utilities_1.handleError, 'state.authenticatedUser');
        const userId = state.authenticatedUser.id;
        if (state.authenticatedUser.is_available) {
            await handleSetUserUnavailable(userId);
        }
        else {
            await handleSetUserAvailable(userId);
        }
    }
    render(state) {
        const availabilityText = determineAvailabilityText(state);
        return (0, lit_html_1.html) `
            <style>
                .${cssName('main-container')} {
                    font-family: Arial, Helvetica, sans-serif;
                    cursor: pointer;
                }

                .${cssName('text-container')} {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    color: white;
                    box-sizing: border-box;
                    padding: 0.25rem 1.5rem;
                }

                .${cssName('green')} {
                    background-color: var(--jo-success);
                }

                .${cssName('red')} {
                    background-color: var(--jo-danger);
                }
            </style>

            <div
                class="${cssName('main-container')}"
                @click=${(e) => this.availabilityClicked(e)}
            >
                <div
                    class="${cssName('text-container')} 
                    ${availabilityText === 'Available' ? cssName('green') : cssName('red')}"
                >
                    ${availabilityText}
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-availability', JOAvailability);
async function handleSetUserAvailable(userId) {
    const timestamp = new Date();
    await (0, user_availability_1.setUserAvailable)(userId);
    await (0, user_status_event_mutations_1.createUserStatusEvent)({
        userId: userId,
        eventType: 'MANUALLY_WENT_AVAILABLE',
        timestamp: timestamp,
    });
    await (0, user_availability_1.kickOffQueue)();
}
async function handleSetUserUnavailable(userId) {
    const timestamp = new Date();
    (0, set_user_back_available_automatically_1.stopTimeoutToSetUserBackAvailable)();
    await (0, user_availability_1.setUserUnavailable)(userId);
    await (0, user_status_event_mutations_1.createUserStatusEvent)({
        userId: userId,
        eventType: 'MANUALLY_WENT_UNAVAILABLE',
        timestamp: timestamp,
    });
}
function determineAvailabilityText(state) {
    if (state.authenticatedUser === 'NOT_SET')
        return '';
    if (state.authenticatedUser.chat_mode === 'STRICT') {
        return 'Strict Chat Mode';
    }
    else {
        return state.authenticatedUser.is_available ? 'Available' : 'Unavailable';
    }
}
