"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTotalChatsPerPodAsString = exports.convertTotalChatsPerPodArrayToString = exports.getTotalChatsPerPod = void 0;
function getTotalChatsPerPod(currentChats) {
    if (currentChats.length === 0) {
        return [];
    }
    const totalChatsPerPod = new Map();
    currentChats.forEach(chat => {
        incrementTotalChatsPerPodBasedOnChatStatus(chat, totalChatsPerPod);
    });
    return convertTotalChatsPerPodMapIntoArray(totalChatsPerPod);
}
exports.getTotalChatsPerPod = getTotalChatsPerPod;
function convertTotalChatsPerPodMapIntoArray(totalChatsPerPod) {
    return Array.from(totalChatsPerPod.entries()).map(entry => {
        return {
            podName: entry[0],
            totalActiveChats: entry[1].totalActiveChats,
            totalUnassignedChats: entry[1].totalUnassignedChats,
        };
    });
}
function incrementTotalChatsPerPodBasedOnChatStatus(chat, totalChatsPerPod) {
    const podName = chat.pod?.name || '';
    const totalActiveChats = totalChatsPerPod.get(podName)?.totalActiveChats || 0;
    const totalUnassignedChats = totalChatsPerPod.get(podName)?.totalUnassignedChats || 0;
    if (chat.status === 'ONGOING') {
        totalChatsPerPod.set(podName, {
            totalActiveChats: totalActiveChats + 1,
            totalUnassignedChats: totalUnassignedChats,
        });
    }
    if (chat.status === 'OPEN' || chat.status === 'RELEASED_BY_JILL') {
        totalChatsPerPod.set(podName, {
            totalActiveChats: totalActiveChats,
            totalUnassignedChats: totalUnassignedChats + 1,
        });
    }
}
function convertTotalChatsPerPodArrayToString(totalChatsPerPod) {
    let resultingString = '';
    totalChatsPerPod.forEach(item => {
        if (item.podName) {
            resultingString += `${item.podName}: Active Chats: ${item.totalActiveChats} Unassigned Chats: ${item.totalUnassignedChats} \n`;
        }
    });
    return resultingString;
}
exports.convertTotalChatsPerPodArrayToString = convertTotalChatsPerPodArrayToString;
function getTotalChatsPerPodAsString(currentChats) {
    return convertTotalChatsPerPodArrayToString(getTotalChatsPerPod(currentChats));
}
exports.getTotalChatsPerPodAsString = getTotalChatsPerPodAsString;
