"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoIntentionallyThrowError = void 0;
const element_vir_1 = require("element-vir");
exports.JoIntentionallyThrowError = (0, element_vir_1.defineElementNoInputs)({
    tagName: 'jo-intentionally-throw-error',
    renderCallback: () => {
        return (0, element_vir_1.html) `
            <button
                ${(0, element_vir_1.listen)('click', () => {
            // @ts-ignore
            intentionallyThrowError();
        })}
            >
                INTENTIONALLY THROW ERROR
            </button>
        `;
    },
});
