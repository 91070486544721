"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stylesForJoTopBar = void 0;
const lit_html_1 = require("lit-html");
const css_utils_1 = require("../../../utilities/css-utils");
const cssName = (0, css_utils_1.cssPrefixer)('jo-top-bar');
const cssNames = (classNames) => classNames.split(' ').map(cssName).join(' ');
exports.stylesForJoTopBar = (() => {
    return (0, lit_html_1.html) `
        <style>
            .${cssNames('main-container')} {
                display: flex;
                height: 100%;
                width: 100%;
                background-color: var(--jo-primary);
                box-sizing: border-box;
                font-family: Arial, Helvetica, sans-serif;
                color: white;
                font-size: 1.2rem;
            }

            .${cssNames('overflow-x-scroll')} {
                overflow-x: scroll;
            }

            .${cssNames('overflow-x-hidden')} {
                overflow-x: hidden;
            }

            .${cssNames('left-container')} {
                width: 100%;
                padding-left: 2rem;
                display: flex;
                flex-basis: 33%;
            }

            .${cssNames('logo')} {
                height: 2.5rem;
                cursor: pointer;
            }

            .${cssNames('item')} {
                padding: 0.25rem 0rem;
                align-self: center;
                word-break: break-word;
            }

            .${cssNames('availability-container')} {
                display: flex;
                align-items: center;
                justify-content: space-around;
                flex-basis: 43%;
                font-size: 1.1rem;
            }

            .${cssNames('nav-items-container')} {
                width: 100%;
                display: flex;
                align-items: center;
                padding-right: 2rem;
                box-sizing: border-box;
                flex-basis: 33%;
                justify-content: flex-end;
            }

            .${cssNames('nav-item')} {
                box-sizing: border-box;
                padding: 1rem;
                cursor: pointer;
            }

            .${cssNames('nav-item')}:hover {
                color: rgba(255, 180, 100);
            }

            .${cssNames('nav-icon')} {
                vertical-align: text-bottom;
            }

            .${cssNames('manage-dropdown-content')} {
                position: absolute;
                margin-top: 0.6rem;
                font-size: 0.8rem;
                background-color: var(--jo-white);
                box-shadow: 0 8px 16px var(--jo-accent-background-dark);
                z-index: var(--z-index-2);
                overflow-y: auto;
                max-height: 90%;
            }

            .${cssNames('manage-dropdown-content')} a {
                display: block;
                color: var(--jo-text-default);
                padding: 1rem 1.5rem;
            }

            .${cssNames('manage-dropdown-content')} a:hover {
                background-color: #fad3a1;
            }

            .${cssNames('profile-dropdown-content')} {
                position: absolute;
                top: 2.5rem;
                margin-top: 0.6rem;
                font-size: 0.8rem;
                background-color: var(--jo-white);
                color: var(--jo-text-default);
                box-shadow: 0 8px 16px var(--jo-accent-background-dark);
                z-index: var(--z-index-2);
                padding: 1rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.6rem;
            }

            .${cssName('align-center')} {
                display: flex;
                align-items: center;
            }

            .jo-top-bar--disabled {
                cursor: not-allowed;
                opacity: 0.6;
            }

            @media screen and (max-width: 1500px) {
                .${cssNames('main-container')} {
                    font-size: 1rem;
                }

                .${cssNames('logo')} {
                    height: 2rem;
                    cursor: pointer;
                }

                .${cssNames('nav-icon')} {
                    vertical-align: text-bottom;
                    font-size: 1.2rem;
                }

                .${cssNames('nav-item')} {
                    box-sizing: border-box;
                    padding: 0.5rem;
                    cursor: pointer;
                }
            }
        </style>
    `;
})();
