"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.saveGoogleAuthToken = void 0;
const graphql_1 = require("../../services/graphql");
const error_handling_1 = require("../error-handling");
const mapped_env_variables_1 = require("../environment/mapped-env-variables");
async function saveGoogleAuthToken(companyId, googleAccountRefreshToken) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($companyId: Int!, $googleAccountRefreshToken: String!) {
                    updateGoogleRefreshTokenForCompany(
                        companyId: $companyId
                        googleAccountRefreshToken: $googleAccountRefreshToken
                    )
                }
            `, {
            companyId,
            googleAccountRefreshToken,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        else {
            return { succeeded: true };
        }
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('saveGoogleAuthToken', error);
    }
}
exports.saveGoogleAuthToken = saveGoogleAuthToken;
