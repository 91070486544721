"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const element_vir_1 = require("element-vir");
const lit_html_1 = require("lit-html");
const dates_and_times_1 = require("../../../utilities/dates-and-times/dates-and-times");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const global_incoming_queue_items_manager_1 = require("../../../utilities/incoming-queue-items/global-incoming-queue-items-manager");
const create_element_bound_interval_1 = require("../../../utilities/timeouts/create-element-bound-interval");
const constants_and_types_queue_status_box_settings_1 = require("../../main-pages/settings-pages/queue-status-box-settings-page/constants-and-types-queue-status-box-settings");
require("../jo-button.element");
require("../jo-input");
const jo_queue_status_box_element_1 = require("../jo-queue-status-box/jo-queue-status-box.element");
class JOIncomingQueueStatusBox extends HTMLElement {
    connectedCallback() {
        global_incoming_queue_items_manager_1.globalIncomingQueueItemsManager.subscribe(this.render.bind(this));
        global_incoming_queue_items_manager_1.globalIncomingQueueItemsManager.initiate();
        (0, create_element_bound_interval_1.createElementBoundInterval)({
            callback: () => {
                this.render();
            },
            element: this,
            intervalMs: dates_and_times_1.oneSecondInMilliseconds,
            intervalName: 'force re-renders',
        });
    }
    render() {
        (0, lit_html_1.render)((0, element_vir_1.html) `
                <${jo_queue_status_box_element_1.JoQueueStatusBox}
                    ${(0, element_vir_1.assign)(jo_queue_status_box_element_1.JoQueueStatusBox, {
            containerTitle: global_incoming_queue_items_manager_1.globalIncomingQueueItemsManager.podNamesWithCounts,
            queueStatItems: [
                {
                    name: jo_queue_status_box_element_1.QueueStatusBoxStatNamesEnum.TOTAL_IN_QUEUE,
                    value: global_incoming_queue_items_manager_1.globalIncomingQueueItemsManager.totalQueueItems,
                },
                {
                    name: jo_queue_status_box_element_1.QueueStatusBoxStatNamesEnum.WAITING,
                    value: global_incoming_queue_items_manager_1.globalIncomingQueueItemsManager.numWaitingQueueItems,
                },
                {
                    name: jo_queue_status_box_element_1.QueueStatusBoxStatNamesEnum.CALLBACKS,
                    value: global_incoming_queue_items_manager_1.globalIncomingQueueItemsManager.numCallbacks,
                },
                {
                    name: jo_queue_status_box_element_1.QueueStatusBoxStatNamesEnum.HIGHEST_POD_COUNT,
                    value: global_incoming_queue_items_manager_1.globalIncomingQueueItemsManager.highestPodCount,
                },
            ],
            renderClockIconIf: shouldDisplayClockForIncomingCalls(),
            type: constants_and_types_queue_status_box_settings_1.QueueStatusBoxType.INCOMING_CALLS,
        })}
                ></${jo_queue_status_box_element_1.JoQueueStatusBox}>
            `, this);
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-incoming-queue-status-box', JOIncomingQueueStatusBox);
function shouldDisplayClockForIncomingCalls() {
    //TODO: ADD THE ABLILTIY TO CUSTOMIZE WHEN THIS GETS DISPLAYED
    //https://jillsoffice.atlassian.net/browse/JWA-2190
    return global_incoming_queue_items_manager_1.globalIncomingQueueItemsManager.longestWaitTime > 4 * dates_and_times_1.oneMinuteInMilliseconds;
}
