"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.endAdminModeIfNecessary = void 0;
const utilities_1 = require("../services/utilities");
const is_in_admin_mode_1 = require("./admin-utils/is-in-admin-mode");
const set_admin_mode_for_user_1 = require("./admin-utils/set-admin-mode-for-user");
async function endAdminModeIfNecessary(authenticatedUser) {
    try {
        if ((0, is_in_admin_mode_1.isInAdminMode)(authenticatedUser)) {
            return;
        }
        await (0, set_admin_mode_for_user_1.setAdminModeForUser)({
            userId: authenticatedUser.id,
            shouldBeInAdminMode: false,
        });
    }
    catch (error) {
        (0, utilities_1.handleError)(`endAdminModeIfNecessary error: ${error}`);
    }
}
exports.endAdminModeIfNecessary = endAdminModeIfNecessary;
