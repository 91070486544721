"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOApp = void 0;
const lit_html_1 = require("lit-html");
const element_vir_1 = require("element-vir");
const save_google_auth_token_if_necessary_1 = require("../../services/save-google-auth-token-if-necessary");
const store_1 = require("../../services/store");
const authenticate_1 = require("../../services/auth/authenticate");
const last_seen_in_chat_mode_1 = require("../../utilities/last-seen-in-chat-mode");
const last_seen_logged_in_1 = require("../../utilities/last-seen-logged-in");
require("./jo-loading");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const create_element_bound_interval_1 = require("../../utilities/timeouts/create-element-bound-interval");
const dates_and_times_1 = require("../../utilities/dates-and-times/dates-and-times");
require("./jo-router.element");
require("./jo-top-bar/jo-top-bar");
const logout_detector_1 = require("../../services/logout-detector");
const jo_intentionally_throw_error_1 = require("./jo-intentionally-throw-error");
const full_web_client_interface_1 = require("../../services/web-client-interface/full-web-client-interface");
/**
 * Ensure that all clients are preloaded. Ideally this wouldn't be necessary here, but currently
 * nothing is using WebClientInterface. Doing this right here is good enough for now.
 */
(0, full_web_client_interface_1.getFullClientInterface)();
class JOApp extends HTMLElement {
    constructor() {
        super();
        store_1.GlobalStore.subscribe(() => (0, lit_html_1.render)(this.render(store_1.GlobalStore.getState()), this));
        // TODO we are disabling all handling of the concept of isOnACallForNow
        // TODO we need to come up with a solution that allows two tabs to be open
        // TODO and allows for the same user to be logged in on multiple m
        // GlobalStore.subscribe(async () => {
        //     await this.setWhetherJillIsOnACall();
        // });
    }
    async connectedCallback() {
        try {
            (0, logout_detector_1.startLogoutDetector)();
            store_1.GlobalStore.dispatch({ type: 'RENDER' });
            await (0, authenticate_1.authenticate)();
            await (0, save_google_auth_token_if_necessary_1.saveGoogleAuthTokenIfNecessary)();
            (0, create_element_bound_interval_1.createElementBoundInterval)({
                callback: () => {
                    if (!(0, last_seen_in_chat_mode_1.isUserInChatMode)()) {
                        /**
                         * Don't clear the interval because the user could be changed to chat mode
                         * later on.
                         */
                        return;
                    }
                    else if (store_1.GlobalStore.authenticatedUser !== 'NOT_SET') {
                        (0, last_seen_in_chat_mode_1.updateLastSeenInChatMode)(store_1.GlobalStore.authenticatedUser.id, new Date());
                    }
                },
                element: this,
                intervalMs: dates_and_times_1.oneSecondInMilliseconds * 30,
                intervalName: 'update last seen in chat mode',
            });
            (0, create_element_bound_interval_1.createElementBoundInterval)({
                callback: () => {
                    if (!(0, last_seen_logged_in_1.isLoggedIn)()) {
                        return;
                    }
                    else if (store_1.GlobalStore.authenticatedUser !== 'NOT_SET') {
                        (0, last_seen_logged_in_1.updateLastSeenLoggedIn)(store_1.GlobalStore.authenticatedUser.id, new Date());
                    }
                },
                element: this,
                intervalMs: dates_and_times_1.oneSecondInMilliseconds * 9,
                intervalName: 'update last seen logged in',
            });
            store_1.GlobalStore.dispatch({ type: 'RENDER' });
        }
        catch (error) {
            alert(error);
            throw new Error(error);
        }
    }
    async setWhetherJillIsOnACall() {
        if (store_1.GlobalStore.authenticatedUser === 'NOT_SET') {
            return;
        }
        const callScreenMode = this.getCallScreenMode();
        // TODO we are disabling all handling of the concept of isOnACallForNow
        // TODO we need to come up with a solution that allows two tabs to be open
        // TODO and allows for the same user to be logged in on multiple machines
        // if (shouldSetUserToNotOnACall(GlobalStore.authenticatedUser, callScreenMode) === true) {
        //     const setJillIsNotOnACallResult = await updateWhetherJillIsOnACall(GlobalStore.authenticatedUser.id, false);
        //     assertSucceeded(setJillIsNotOnACallResult, handleError);
        // }
        // if (shouldSetUserToIsOnACall(GlobalStore.authenticatedUser, callScreenMode) === true) {
        //     const setJillToIsOnACallResult = await updateWhetherJillIsOnACall(GlobalStore.authenticatedUser.id, true);
        //     assertSucceeded(setJillToIsOnACallResult, handleError);
        // }
    }
    getCallScreenMode() {
        const callScreenMode = new URLSearchParams(store_1.GlobalStore.route.search).get('callScreenMode');
        return callScreenMode;
    }
    // TODO we probably want to get better types for this, maybe
    handleSetterAction(e) {
        store_1.GlobalStore[e.detail.key] = e.detail.value;
    }
    handleAction(e) {
        store_1.GlobalStore.dispatch(e.detail);
    }
    actionItemSent() {
        store_1.GlobalStore.dispatch({
            type: 'RESET_CALL_STATE',
        });
    }
    handleCompanyDetailsInputValuesChanged(e) {
        store_1.GlobalStore.dispatch({
            type: 'COMPANY_DETAILS_INPUT_VALUES_CHANGED',
            companyDetailsInputValues: e.detail,
        });
    }
    handleCompanyDetailsCompanyAboutToChange() {
        store_1.GlobalStore.dispatch({
            type: 'COMPANY_DETAILS_INPUT_VALUES_RESET_SNAPSHOT',
        });
    }
    handleCallInstructionsGeneralSetupInputValuesChanged(e) {
        store_1.GlobalStore.dispatch({
            type: 'CALL_INSTRUCTIONS_GENERAL_SETUP_INPUT_VALUES_CHANGED',
            callInstructionsGeneralSetupInputValues: e.detail,
        });
    }
    handleCallInstructionsGeneralSetupCompanyAboutToChange() {
        store_1.GlobalStore.dispatch({
            type: 'CALL_INSTRUCTIONS_GENERAL_SETUP_INPUT_VALUES_RESET_SNAPSHOT',
        });
    }
    handleServicesPricingInputValuesChanged(e) {
        store_1.GlobalStore.dispatch({
            type: 'SERVICES_PRICING_INPUT_VALUES_CHANGED',
            servicesPricingInputValues: e.detail,
        });
    }
    handleServicesPricingCompanyAboutToChange() {
        store_1.GlobalStore.dispatch({
            type: 'SERVICES_PRICING_INPUT_VALUES_RESET_SNAPSHOT',
        });
    }
    handleContactSaveInputValuesChanged(e) {
        store_1.GlobalStore.dispatch({
            type: 'CONTACT_SAVE_INPUT_VALUES_CHANGED',
            contactSaveInputValues: e.detail,
        });
    }
    handleContactSaveContactAboutToChange() {
        store_1.GlobalStore.dispatch({
            type: 'CONTACT_SAVE_INPUT_VALUES_RESET_SNAPSHOT',
        });
    }
    handleCallAnsweredChanged(e) {
        const callAnswered = e.detail;
        store_1.GlobalStore.currentCallInformation = {
            ...store_1.GlobalStore.currentCallInformation,
            callAnswered,
        };
    }
    handleBlockingNavigationChanged(e) {
        const blockingNavigation = e.detail;
        store_1.GlobalStore.route = {
            ...store_1.GlobalStore.route,
            blockingNavigation,
        };
    }
    handleCanInitiateNewTransferChanged(e) {
        store_1.GlobalStore.currentCallInformation = {
            ...store_1.GlobalStore.currentCallInformation,
            canInitiateNewTransfer: e.detail,
        };
    }
    render(globalState) {
        return (0, element_vir_1.html) `
            <style>
                .jo-app--container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .jo-app--grid {
                    display: grid;
                    grid-template-rows: min-content auto;
                    height: 100vh;
                    width: 100vw;
                    box-sizing: border-box;
                }

                .jo-app--router-container {
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                    overflow-y: hidden;
                }
            </style>

            ${(0, element_vir_1.renderIf)(globalThis.shouldShowSecret, (0, element_vir_1.html) `
                    <${jo_intentionally_throw_error_1.JoIntentionallyThrowError}></${jo_intentionally_throw_error_1.JoIntentionallyThrowError}>
                `)}

            <jo-loading .loading=${globalState.loading}></jo-loading>

            <div
                class="jo-app--container"
                @action=${(e) => this.handleAction(e)}
                @actionitemsent=${() => this.actionItemSent()}
                @blockingnavigationchanged=${(e) => this.handleBlockingNavigationChanged(e)}
                @callansweredchanged=${(e) => this.handleCallAnsweredChanged(e)}
                @callinstructionsgeneralsetupcompanyabouttochange=${() => this.handleCallInstructionsGeneralSetupCompanyAboutToChange()}
                @callinstructionsgeneralsetupinputvalueschanged=${(e) => this.handleCallInstructionsGeneralSetupInputValuesChanged(e)}
                @caninitiatenewtransferchanged=${(e) => this.handleCanInitiateNewTransferChanged(e)}
                @companydetailscompanyabouttochange=${() => this.handleCompanyDetailsCompanyAboutToChange()}
                @companydetailsinputvalueschanged=${(e) => this.handleCompanyDetailsInputValuesChanged(e)}
                @contactsavecontactabouttochange=${() => this.handleContactSaveContactAboutToChange()}
                @contactsaveinputvalueschanged=${(e) => this.handleContactSaveInputValuesChanged(e)}
                @servicespricingcompanyabouttochange=${() => this.handleServicesPricingCompanyAboutToChange()}
                @servicespricinginputvalueschanged=${(e) => this.handleServicesPricingInputValuesChanged(e)}
                @setteraction=${(e) => this.handleSetterAction(e)}
            >
                <div class="jo-app--grid">
                    <div class="jo-app--router-container">
                        <jo-router
                            .props=${{
            authenticatedUser: globalState.authenticatedUser,
            currentCallInformation: globalState.currentCallInformation,
            isMobileDevice: globalState.isMobileDevice,
            mobileTopBarTitle: globalState.mobileTopBarTitle,
            route: globalState.route,
            showSidebar: globalState.showSidebar,
            twilioDevice: globalState.twilioDevice,
        }}
                        ></jo-router>
                    </div>
                </div>
            </div>
        `;
    }
}
exports.JOApp = JOApp;
(0, define_custom_element_1.defineCustomElement)('jo-app', JOApp);
